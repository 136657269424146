<template>
  <b-modal
    id="other_expenses_guide_modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span v-if="tissGuide?.id">Atualizar guia de outras despesas</span>
        <span v-else>Nova guia de outras despesas</span>
      </div>
      <div class="inline-header-actions">
        <TopBarButtonsModule
          :form="form"
          :tissGuide="tissGuide"
          @openSpsadtGuide="startOpenSpsadtGuide"
        />
        <span class="icon-box"><Close class="icon stroke" @click="closeModal"  /></span>
      </div>
    </div>

    <div class="around-content">
      
      <!-- <GuideDataModule
        :form="form"
        :fields="{related_invoice : true, situation : false}"
        :validated="validated"
        :readonly="readonly"
        :tissBatchId="tissGuide?.tiss_batch_id"
        :tissGuide="tissGuide"
      /> -->

      <b-row>
        <b-col cols="12">
          <p class="form-title mb-3">Dados da operadora</p>
        </b-col>

        <b-col cols="4">
          <label for="ans">Registro ANS</label>
          <b-form-input
            id="ans"
            v-model="form.ans"
          />
        </b-col>
        <b-col cols="4">
          <label for="expensable_id">Nº da guia referenciada</label>
          <multiselect
            id="expensable_id"
            track-by="id"
            v-model="form.expensable"
            placeholder="Selecionar"
            :options="referencedGuidesNumbers"
            label="label"
            :showLabels="false"
            :searchable="true"
            :internal-search="false"
            class="with-border"
            :disabled="readonly"
            @select="setType"
          >
            <template slot="caret">
              <div class="chevron">
                <ChevronDown />
              </div>
            </template>
            <template slot="noOptions"> Nenhuma opção </template>
            <template slot="noResult"> Nenhum resultado </template>
          </multiselect>
          <div v-if="validated && !form?.expensable_id" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-col>

        <b-col cols="4">
          <label for="referenced_guide_issue_date" class="title-styles">Data emissão guia referenciada</label>
          <date-picker
            v-model="form.referenced_guide_issue_date"
            format="DD/MM/YYYY"
            placeholder="DD/MM/AAAA"
            :lang="langDatePicker"
            :clearable="false"
            :disabled="true"
            class="full"
            autocomplete="off"
          />
        </b-col>

        <b-col cols="12">
          <hr id="hr" />
        </b-col>
      </b-row>

      <ExecutorContractorDataModule
        :form="form"
        guide="items"
        :readonly="readonly || Boolean(form.expensable_id)"
        :validated="validated"
        :contracteds="contracteds"
      />

      <ExpensesModule
        :form="form"
        :readonly="readonly"
        :clinicHealthPlan="clinicHealthPlan"
      />

      <div class="row">
        <div class="col">
          <label for="total_value_of_medicinal_gases"><Ellipsis>Gases Medicinais</Ellipsis></label>
          <money
            :value="getTotalByCD('01')"
            class="form-control"
            readonly
          />
        </div>
        <div class="col">
          <label for="total_value_of_medicines"><Ellipsis>Medicamentos</Ellipsis></label>
          <money
            :value="getTotalByCD('02')"
            class="form-control"
            readonly
          />
        </div>
        <div class="col">
          <label for="total_value_of_materials"><Ellipsis>Materiais</Ellipsis></label>
          <money
            :value="getTotalByCD('03')"
            class="form-control"
            readonly
          />
        </div>
        <div class="col">
          <label for="total_value_of_opme"><Ellipsis>OPME</Ellipsis></label>
          <money
            :value="getTotalByCD('08')"
            class="form-control"
            readonly
          />
        </div>
        <div class="col">
          <label for="total_amount_of_fess_and_rents"><Ellipsis>Taxas e alugueis</Ellipsis></label>
          <money
            :value="getTotalByCD('07')"
            class="form-control"
            readonly
          />
        </div>
        <div class="col">
          <label for="total_daily_rates"><Ellipsis>Diárias</Ellipsis></label>
          <money
            :value="getTotalByCD('05')"
            class="form-control"
            readonly
          />
        </div>
        <div class="col">
          <label for="final_amount"><Ellipsis>Total Geral</Ellipsis></label>
          <money
            :value="getTotalValues()"
            class="form-control"
            readonly
          />
        </div>
      </div>

      <div class="wrapper-button">
        <b-button
          class="wh-button mr-2"
          variant="outline-primary"
          outline
          v-if="tissGuide?.id"
          @click="print"
        >
          Imprimir guia
        </b-button>

        <b-button
          v-if="tissGuide?.id && !readonly"
          variant="primary"
          size="lg"
          @click="updateGuide"
          :disabled="alreadySent"
        >
          Atualizar guia
        </b-button>
        <b-button
          v-else-if="!readonly"
          variant="primary"
          size="lg"
          @click="createGuide"
          :disabled="alreadySent"
        >
          Salvar guia
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
// import api from '@/modules/tiss/manageGuides/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { GUIDE_TYPE_SPSADT, GUIDE_TYPE_HOSPITALIZATION_SUMMARY } from '@/modules/tiss/manageGuides/utils/guideHelper'

export default {
  name: 'ExpenseGuideModal',
  components: {
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    Close: () => import('@/assets/icons/close.svg'),
    Ellipsis: () => import('@/components/General/Ellipsis'),
    ExecutorContractorDataModule: () => import('./Modules/ExecutorContractorDataModule.vue'),
    ExpensesModule: () => import('./Modules/ExpensesModule.vue'),
    // GuideDataModule: () => import('./Modules/GuideDataModule.vue'),
    TopBarButtonsModule: () => import('./Modules/TopBarButtonsModule')
  },
  props: {
    patient: Object,
    healthPlan: Object,
    invoicing_tiss_setting: Object,
    appointmentId: String,
    tissGuide: Object,
    providerGuideUpdated: Number,
    clinicHealthPlan: Object,
    contracteds: Array
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      validated: false,
      form: {},
      langDatePicker: {
        formatLocale: {
          weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
        }
      },
      referencedGuide: null,
      referencedGuideId: null,
      referencesGuideNumbers: [],
      alreadySent: false
    }
  },
  computed: {
    readonly() {
      return Boolean(
        this.tissGuide && Boolean(Object.keys(this.tissGuide).length)
        && (['Finalizada'].includes(this.tissGuide?.situation)) || 
          (this.tissGuide?.tiss_batch_id || this.tissGuide?.expensable?.tiss_guide?.tiss_batch_id)) 
    },
    referencedGuidesNumbers() {
      return this.referencesGuideNumbers.filter(option => !option.expensable_id || option.expensable_id === this.referencedGuideId)
    },
  },
  methods: {
    async getReferencedGuide(id) {
      this.referencedGuide = null
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.getTissGuideById(id)
        this.referencedGuide = response.data
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getDefaultForm() {
      return {
        clinic_id: this.clinic.id,
        expensable_id: null,
        expensable_type: null,
        ans: this.healthPlan?.ans_code,
        referenced_guide_issue_date: null,
        operator_code: this.invoicing_tiss_setting?.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.other_expenses_guide ? this.invoicing_tiss_setting.hired_executant_code: null,
        contractor_name: this.invoicing_tiss_setting?.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.other_expenses_guide ? this.invoicing_tiss_setting.hired_executant_name: null,
        contractor_cnes: this.invoicing_tiss_setting?.tiss_using_guide_type && this.invoicing_tiss_setting.tiss_using_guide_type.other_expenses_guide ? this.invoicing_tiss_setting.CNES: null,
        items: [],
        type: null,
      }
    },
    clearForm() {
      this.form = this.getDefaultForm()
      this.validated = false
      this.referencedGuideId = null
    },
    isValidForm() {
      this.validated = true;
      return this.form.expensable_id
    },
    async onShow() {
      await this.getReferencedGuidesForExpense()
      
      if (this.providerGuideUpdated) {
        const expensable = this.referencesGuideNumbers.find(option => option.label.includes(this.providerGuideUpdated))
        if (expensable) {
          this.form.expensable = expensable
          this.setType(expensable)
        }
      }
    },
    print() {
      this.$emit('print', this.tissGuide)
    },
    getPayload() {
      return {
        id: this.form.id,
        clinic_id: this.clinic.id,
        expensable_id: this.form.expensable_id,
        expensable_type: this.form.expensable_type,
        ans: this.form.ans,
        referenced_guide_issue_date: this.form.referenced_guide_issue_date,
        operator_code: this.form.operator_code,
        contractor_name: this.form.contractor_name,
        contractor_cnes: this.form.contractor_cnes,
        items: this.form.items,
       }
    },
    async createGuide() {
      if(!this.isValidForm()) return;
      this.alreadySent = true
      const isLoading = this.$loading.show();
      try {
        const payload = this.getPayload()
        const { data } = await this.api.createTissGuideOtherExpense(payload);
        this.$toast.success('Guia de despesas criada com sucesso!');
        this.closeModal()
        this.$emit('refresh-tiss-guides', { 'id': data.id , 'guide_id': this.tissGuide.id, 'other_expenses': true })
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        this.alreadySent = false
        isLoading.hide();
      }
    },
    closeModal() {
      this.clearForm();
      this.$emit('onHide');
      this.$bvModal.hide('other_expenses_guide_modal');
    },
    async updateGuide() {
      if(!this.isValidForm()) return;
      this.alreadySent = true
      const isLoading = this.$loading.show();
      try {
        const payload = this.getPayload()
        const { data } = await this.api.updateTissGuideOtherExpense(this.tissGuide.id, payload);
        this.$toast.success('Guia de despesas atualizada com sucesso!');
        this.$emit('refresh-tiss-guides', { 'id': data.id , 'guide_id': this.tissGuide.expensable.tiss_guide.id, 'other_expenses': true })
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message);
      } finally {
        this.alreadySent = false
        isLoading.hide();
      }
    },
    getTotalByCD(code) {
      return this.form.items.filter(item => item.cd === code).reduce((acc, item) => acc + (item.quantity * item.unitary_value), 0)
    },
    getTotalValues() {
      return this.form.items.reduce((acc, item) => acc + (item.quantity * item.unitary_value), 0)
    },
    setType(option) {
      this.form.type = option.label.split('-')[0]
      this.form.expensable_id = option.id
      this.form.expensable_type = option.expensable_type
      this.form.referenced_guide_issue_date = option.guide_issue_date
    },
    startOpenSpsadtGuide(id) {
      this.closeModal()
      this.$emit('openSpsadtGuide', id)
    },
    async getReferencedGuidesForExpense() {
      try {
        const { data } = await this.api.getReferencedGuidesForExpense(this.patient?.id, this.invoicing_tiss_setting?.id)
        this.referencesGuideNumbers = data.reduce((acc, guide) => {
          if (
            (guide.guide_type === GUIDE_TYPE_SPSADT || guide.guide_type === GUIDE_TYPE_HOSPITALIZATION_SUMMARY) && 
            (!guide.guide?.expensable?.id || (this.tissGuide?.expensable_id && guide.guide?.expensable?.expensable_id === this.tissGuide?.expensable_id))
          ) {
            acc.push({
              id: guide.guide_id,
              label: (guide.guide_type === GUIDE_TYPE_SPSADT ? 'SP/SADT - Nº  ' : 'Resumo de internação - Nº ') + guide.provider_guide,
              guide_issue_date: this.moment(guide.guide.guide_issue_date).toDate(),
              expensable_type: guide.guide_type,
              associated_expensable_id: guide?.guide?.expensable_id
            })
          }
          return acc
        }, [])
        .filter(function (a) {
          return !this[JSON.stringify(a)] && (this[JSON.stringify(a)] = true);
        }, Object.create(null))
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
  },
  watch: {
    patient: {
      async handler(val) {
        if (val?.id) {
          await this.getReferencedGuidesForExpense()
        }
      },
      immediate: true
    },
    tissGuide: {
      async handler(newValue) {
        if (newValue?.expensable_id) {
          this.form = {
            ...this.form,
            ...newValue
          }

          this.form.referenced_guide_issue_date = newValue?.referenced_guide_issue_date ? this.moment(newValue.referenced_guide_issue_date).toDate() : null
          this.form.situation = newValue.expensable.tiss_guide.situation
          this.referencedGuideId = newValue.expensable_id

          await this.getReferencedGuidesForExpense()
          const expensable = this.referencesGuideNumbers.find(option => option.id === newValue.expensable_id)
          if (expensable) {
            this.form.expensable = expensable
            this.setType(expensable)
          }
        } else {
          this.clearForm()
        }
      },
      immediate: true
    },
    'form.expensable_id': function(val, oldVal) {
      if(val?.tissId && val?.tissId !== oldVal?.tissId) this.getReferencedGuide(val.tissId)
    },
    'referencedGuide': function(val, oldVal) {
      if(val) {
        this.form.referenced_guide_issue_date = val?.guide?.guide_issue_date ? this.moment(val?.guide?.guide_issue_date).toDate() : null
        this.form.operator_code = val?.guide?.operator_code ?? null
        this.form.contractor_name = val?.guide?.contractor_name_requesting ?? null
        this.form.contractor_cnes = val?.guide?.contractor_cnes ?? null
      }
      else{
        this.form.referenced_guide_issue_date = null
        this.form.operator_code = null
        this.form.contractor_name = null
        this.form.contractor_cnes = null
      }
    },
    providerGuideUpdated: {
      async handler(newValue) {
        if (!newValue) return
        const expensable = this.referencesGuideNumbers.find(option => option.label.includes(newValue))
        if (expensable) {
          this.form.expensable = expensable
          this.setType(expensable)
        }
      },
    },
  },
}
</script>

<style lang="scss">
#other_expenses_guide_modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: #525C7A;
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }

    .around-content {
      padding: 24px 24px 0 24px;
    }

    .wh-button {
      color: var(--blue-500);
    }
  }

  .inline-header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .wrapper-button {
    width: 100%;
    display: inline-flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
    }
  }

  label {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #0a184c;
    margin: 0;
  }

  .form-title{
    color:#304388;
    font-size: 18px;
    font-weight: bolder;
    line-height: 28px;
  }

  #hr {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
  }
}
</style>
